import React from 'react';
import './App.css';
 
function App() {
  return (
    <div>
    <div className="container">
        Murray Lee
    </div>
    <div className="about">
        Austin, Texas
    </div>
    <div className="contact">
        <p itemprop="email" class="email"><a href="mailto:murraylee10@gmail.com">contact</a></p>
    </div>
    </div>
  );
}
 
export default App;
